import logo from './horizontal-fill-gold.svg';
import footerLogo from './footer-logo.svg';
import './App.css';
import './index.css';
import React, { useState } from 'react'

const FONTS = [
  {
    name: 'Bressay Devanagari',
    fontName: 'bressay',
    fontStyle: 'standard'
  },
  {
    name: 'Clarendon URW',
    fontName: 'clarendon',
    fontStyle: 'standard'
  },
  {
    name: 'Poppins',
    fontName: 'poppins',
    fontStyle: 'standard'
  },
  {
    name: 'Roboto',
    fontName: 'roboto',
    fontStyle: 'standard'
  },
  {
    name: 'Obvia',
    fontName: 'obvia',
    fontStyle: 'standard'
  },
  {
    name: 'Hoss Round',
    fontName: 'hoss-round',
    fontStyle: 'standard'
  },
  {
    name: 'Amador',
    fontName: 'amador',
    fontStyle: 'standard'
  },
  {
    name: 'Samantha',
    fontName: 'samantha',
    fontStyle: 'script'
  },
  {
    name: 'Number Five',
    fontName: 'number-five',
    fontStyle: 'script'
  },
  {
    name: 'Gioviale',
    fontName: 'gioviale',
    fontStyle: 'script'
  },
  {
    name: 'Spumante',
    fontName: 'spumante',
    fontStyle: 'script'
  },
  {
    name: 'Tangier',
    fontName: 'tangier',
    fontStyle: 'script'
  },
  {
    name: 'Funkydori',
    fontName: 'funkydori',
    fontStyle: 'script'
  },
];

const FONTSTYLES = [
  {
    name: 'Standard',
    fontStyle: 'standard',
  },
  {
    name: 'Script',
    fontStyle: 'script',
  }
];

function App() {
  const [textInput, setText] = useState('The quick brown fox jumps over the lazy dog.');
  const [fontStyleFilter, setFontStyle] = useState('');
  return (
    <div className="App md:w-screen lg:w-full m-auto bg-gray-100">
      <div className="font-grid inline-grid grid-cols-1 p-5 w-screen m-auto bg-black">
        <img src={logo} className="max-h-20 m-auto" alt="logo" />
      </div>
      <div className="font-grid inline-grid grid-cols-1 p-5 w-screen m-auto">
        <div className="font-grid__item text-2xl text-left grid grid-cols-5">
          <div className="grid grid-cols-1 col-span-5 md:col-span-3">
            <p className="text-left col-span-2 p-3 pb-1 px-0 interstate">
              Sample Text:
            </p>
            <input type="text" placeholder="Text input here." onChange={e => setText(e.target.value)} className="w-full md:w-11/12 p-2 m-3 mx-0 rounded border-black border col-span-2 h-auto bg-white" />
          </div>
          <div className="grid grid-cols-2 col-span-5 md:col-span-2 gap-x-4">
            <h1 className="text-left col-span-2 p-3 pb-1 px-0 interstate">
              Font Styles:
            </h1>
            {FONTSTYLES.map(fontStyle => (
              <button className={fontStyle.fontStyle === fontStyleFilter ? "rounded border border-black flex justify-center text-center m-3 p-2 px-0 bg-black text-white mx-0 w-full interstate" : "rounded border border-black flex justify-center text-center m-3 p-2 px-0 mx-0 w-full interstate bg-white"}
                onClick={() => {
                  fontStyle.fontStyle === fontStyleFilter ? setFontStyle('') : setFontStyle(fontStyle.fontStyle);
                }}>
                {fontStyle.name}
              </button>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-1">
          {FONTS.map(font => (
            (font.fontStyle === fontStyleFilter || fontStyleFilter === '') &&
            <div className="border border-black rounded m-3 mx-0 p-5 grid grid-cols-1">
              <div key={font.name} className={getClassNames(font.fontName)}>
                {font.name}
              </div>
              <div className={getChildClassName(font.fontName)}>
                {textInput || 'The quick brown fox jumps over the lazy dog.'}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="font-grid inline-grid grid-cols-1 p-5 w-screen m-auto bg-black">
        <img src={footerLogo} className="m-auto max-h-16" alt="logo" />
        <p className="text-white interstate pt-3">
          Copyright © <Year />. All Rights Reserved by 14K Inc.
        </p>
      </div>
    </div >
  );
}

function getClassNames(fontName) {
  return `font-grid__item text-lg md:text-xl text-left ${fontName}`;
}

function getChildClassName(fontName) {
  return `pt-3 text-xl md:text-2xl break-all text-left ${fontName}`;
}

function Year() {
  var d = new Date();
  return d.getFullYear();
}

export default App;
